.game-container {
    position: relative;
    width: 360px;
    height: 640px;
    /* margin: 0 auto; */
    background: #000;
  }
  
  .game-info {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #fff;
    font-family: 'Press Start 2P', cursive;
  }
  
  .score {
    font-size: 20px;
  }
  
  .lives {
    font-size: 20px;
  }
  
  .game-over {
    position: absolute;
    top: 500%;
    left: 260%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    color: #fff;
    text-align: center;
  }
  
  .level-text {
    position: absolute;
    top: 500%;
    left: 250%;
    transform: translate(-50%, -50%);
    font-size: 48px;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px 4px #000;
    opacity: 1;
    transition: opacity 1s ease-out;
    text-align: center;
  }
  
  .start-button, .retry-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    background-color: #007bff; /* Цвет кнопки */
    border: none;
    border-radius: 5px;
    color: #fff;
    transition: background-color 0.3s;
  }
  
  .start-button:hover, .retry-button:hover {
    background-color: #0056b3; /* Цвет кнопки при наведении */
  }
  


  .game-over {
    text-align: center;
  }
  
  .submit-button, .retry-button {
    margin: 10px;
  }
  
  .high-scores {
    margin-top: 20px;
  }
  
  .high-scores ul {
    list-style-type: none;
    padding: 0;
  }
  
  .high-scores li {
    margin-bottom: 5px;
  }


  .slider-container {
    display: flex;
    justify-content: center;
    height: 100px;
    position: absolute;
    bottom: 100px;
    width: 100%;
  }
  
  .slider2 {
    width: 80%; /* Ширина бегунка */
    -webkit-appearance: none; /* Убираем стандартный стиль */
    background: transparent; /* Прозрачный фон */
    height: 100px;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Убираем стандартный стиль */
    appearance: none;
    width: 50px; /* Ширина бегунка */
    height: 50px; /* Высота бегунка */
    background: #007bff; /* Цвет бегунка */
    opacity: 0;
    border-radius: 50%; /* Закругляем уголки */
    cursor: pointer; /* Курсор на бегунке */
  }
  
  .slider::-moz-range-thumb {
    width: 30px; /* Ширина бегунка */
    height: 30px; /* Высота бегунка */
    background: #007bff; /* Цвет бегунка */
    border-radius: 50%; /* Закругляем уголки */
    cursor: pointer; /* Курсор на бегунке */
  }
  
  .slider::-ms-thumb {
    width: 30px; /* Ширина бегунка */
    height: 30px; /* Высота бегунка */
    background: #007bff; /* Цвет бегунка */
    border-radius: 50%; /* Закругляем уголки */
    cursor: pointer; /* Курсор на бегунке */
  }
  
  