.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  margin: 50px 350px;
}

.header-left {
  flex-grow: 1;
  padding-bottom: 5rem;
}

.header-left p {
  margin: 0;
  color: #007ABB;
}

.header-right img {
  width: 100%;
  height: auto;
}

.img1{
  /* height: 400px; */
}


.logo {
  width: 150px;
  padding-top: 20px;
}

.headText {
  font-size: 40px;
}










.links {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  cursor: pointer;

}

.link {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: flex-end; */
  justify-content: center;
  height: 420px;
  /* Высота блока с текстом */
  overflow: hidden;
  transition: transform 0.3s ease;
  height: 400px;
  /* Анимация увеличения */
}


.link img {
  width: 100%;
  /* height: 100%; */
  /* Ширина картинки */
  /* height: auto; */
  /* Автоматическая высота */
  transition: transform 0.5s ease, filter 0.5s ease;
  /* Анимация увеличения */

}

.link:hover img {
  transform: scale(1.10);
  /* Увеличение размера картинки при наведении */
  filter: brightness(0.5);
  /* Затемнение картинки при наведении */

}

.link span {
  position: absolute;
  bottom: 50%;
  /* Отступ снизу */
  left: 50%;
  transform: translateX(-50%);
  /* Центрирование текста по горизонтали */
  color: white;
  font-weight: bold;
  font-size: 30px;
  padding: 10px 20px;
  /* Внутренние отступы */
  border-radius: 5px;
  /* Скругление углов */
  transition: opacity 0.3s ease;
  /* Анимация изменения прозрачности */
  opacity: 1;
  /* Скрыть текст по умолчанию */
}

.link:hover span {
  opacity: 1;
  /* Показать текст при наведении */
}


.img3 {
  width: 130% !important;
}

.img2 {
  width: 110% !important;
}

.hr {
  background-color: #0089E5;
  position: absolute;
  bottom: 47%;
  z-index: 99;
  width: 130px;
  height: 3px;
  border: none;
}

.linkText {
  cursor: pointer;
}

@media screen and (min-width: 1600px) and (max-width: 2900px) {
  .link{
    /* min-height: 650px; */
  }


}


@media screen and (min-width: 1024px) and (max-width: 1600px) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
    margin: 20px 30px;
  }

  .link {
    position: relative;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: flex-end; */
    justify-content: center;
    /* height: 332px; */
    height: 265px;
    overflow: hidden;
    transition: transform 0.3s ease;
  }

  .link span {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-weight: bold;
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    transition: opacity 0.3s ease;
    opacity: 1;
  }
}



@media screen and (min-width: 576px) and (max-width: 992px) {

  .header {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background-color: #ffffff;
    margin: 0;
  }

  .links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    cursor: pointer;
  }

}


@media screen and (max-width: 576px) {

  .headText {
    font-size: 28px;
  }

  .link span {
    font-size: 20px;
  }

  .logo {
    width: 100px;
    padding-top: 20px;
  }

  .header {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background-color: #ffffff;
    margin: 0;
  }

  .links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    cursor: pointer;
  }
}