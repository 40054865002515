/* MODRATOR PAGE */

.color {
  /* display: flex; */
  justify-content: center;
}

motorcycle-list{
  display: flex;
  overflow-x: auto;
}

.modalM {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-contentM {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
}

.modal-actionsM {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.modal-actionsM button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-actionsM button:hover {
  background-color: #ddd;
}

.color-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #000;
}



/* MODERATOR PAGE END */









.motorcycles-container {
  padding: 2rem 5rem;
  margin: 0 auto;
  padding-top: 60px;
}

.motorcycles-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(300px, 1fr));
  grid-gap: 20px;
}

.motorcycle-card {
  position: relative;
  overflow: hidden;
  /* Чтобы обрезать изображение */
  border: 1px solid #ccc;
  height: 350px;
}

.motorcycle-image {
  position: relative;
}

.motorcycle-image img {
  width: 100%;
  height: 350px;
  /* Занимает всю высоту контейнера */
  transition: transform 0.3s ease;
  /* Добавляем плавный переход для изображения */
  object-fit: cover;
  /* Изображение будет заполнять весь контейнер, сохраняя пропорции */

}


.motorcycle-card:hover img {
  transform: translateX(20px)
    /* Сдвигаем изображение на 20px вправо при наведении */

}

.motorcycle-details {
  position: absolute;
  bottom: 0;
  /* Текст внизу */
  left: 0;
  padding: 20px 40px;
  color: rgb(0, 0, 0);
  width: 100%;
  box-sizing: border-box;
  transition: bottom 0.3s ease;
  /* Добавляем плавный переход для текста */
}

.motorcycle-details h2,
.motorcycle-details p {
  margin: 0;
}

.motorcycle-details p {
  margin-top: 10px;
}

.motorcycle-card:hover .motorcycle-details {
  bottom: 20px;
  /* Сдвигаем текст на 20px вниз при наведении */
}

.motorcycle-card:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 55, 138);
  /* Прозрачный синий фон */
}

.motorcycle-card:hover img {
  filter: brightness(80%) saturate(100%) opacity(0.9);


}

.motoImage {
  width: 80%;
}

.motoImageMini {
  width: 40%;
}

.motoPage {
  display: flex;
  padding: 1rem 3rem;
  justify-content: center;
}

.motoPageRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 100px;

}

.motoPageLeft {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.motoBox {
  display: flex;
}

.motoBoxPrice {
  display: flex;
  justify-content: center;
  gap: 280px;
}

.arrow {
  text-decoration: none;
  font-size: 70px;
  color: black;
  transition: 0.5s ease;
  font-weight: bold;

}

.arrow:hover {
  color: #E30007;
  transition: 0.5s ease;

}

.btn2 {
  width: 200px;
  padding: 10px;
  margin-top: 30px;
  background: #E30007;
  color: rgb(244, 235, 235);
  border: solid 3px;
  border-color: #E30007;
  transition: 0.5s ease;
  border-radius: 7px;
}

.btn2:hover {
  color: #E30007;
  background: none;

}

.btn3 {
  width: 100%;
  padding: 10px;
  margin-top: 30px;
  background: none;
  color: #E30007;
  border: solid 3px;
  border-color: #E30007;
  transition: 0.5s ease;
}

.btn3:hover {
  background: #E30007;
  color: rgb(244, 235, 235);

}

.motoPageRight p {
  margin: 0;
  font-size: 20px;
}

.motoPageRight h1 {
  font-size: 40px;
}

.motoPageRight h2 {
  font-size: 33px;

}

.title-head {
  display: flex;
  gap: 200px;

}

/* .left {
  position: absolute;
  left: 30px;
  top: 75px;
}

.right {
  position: absolute;
  top: 75px;
  left: 850px;
} */

.red {
  color: #E30007;
}


.titleArrows {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: end;
}


@media screen and (min-width: 576px) and (max-width: 992px) {



  .motorcycles-container {
    padding: 2rem 1rem;
    margin: 0 auto;
    padding-top: 60px;
  }

  .motorcycles-container h1 {
    font-size: 25px;
  }

  .motorcycles-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    grid-gap: 20px;
  }
}



@media screen and (max-width: 576px) {

  .arrow {
    margin-bottom: 7px;
  }

  .titleArrows {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .motorcycles-container {
    padding: 2rem 1rem;
    margin: 0 auto;
    padding-top: 20px;
  }

  .motorcycles-container h1 {
    font-size: 20px;
    margin-bottom: 25px;
  }

  .motorcycles-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(300px, 1fr));
    grid-gap: 20px;
  }

  .motoPageLeft {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .motoPage {
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;
    justify-content: center;
  }

  .title {
    font-size: 23px;
  }



  .arrow {
    text-decoration: none;
    font-size: 36px;
    color: black;
    transition: 0.5s ease;
    font-weight: bold;
  }

  .motoPageRight {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1800px) {


  .motorcycles-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(300px, 1fr));
    grid-gap: 20px;
  }

  .motorcycles-container {
    padding: 5rem 1rem;
    margin: 0 auto;
    padding-top: 60px;
  }

  .title {
    font-size: 25px;
  }


  .arrow {
    font-size: 40px;
  }


}




@media screen and (min-width: 576px) and (max-width: 1024px) {


  .title {
    font-size: 16px;
  }

  .arrow {
    font-size: 40px;
  }

}