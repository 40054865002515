.prefooter-section2 {
    padding: 100px 0px;


}


.prefooter-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #EEEEEE;
    padding: 80px 0px;

}

.fa-icon {
    stroke-width: 1px;
    /* Устанавливаем толщину линии в пикселях */
}

.preFooter-kontakt {
    display: flex;
}

.images-container2 {
    display: flex;
    justify-content: space-around;
}

.images-container {
    display: flex;
    justify-content: space-around;
}

.preFooter-kontakt {
    display: flex;
    align-items: center;
    gap: 80px;
}

.a {
    color: black;
}



@media screen and (min-width: 576px) and (max-width: 992px) {

    .images-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .images-container img {
        width: 55%;
    }

    .images-container2 {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        gap: 40px;
    }

    .preFooter-kontakt {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 80px;
    }

}



@media screen and (max-width: 576px) {

    .images-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

    }
    .images-container img {
        width: 55%;
    }

    .images-container2 {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        gap: 40px;
    }

    .preFooter-kontakt {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0;
    }
    
    .kontakt, .mark, .time{
        margin: 0 50px;
    }

}