.navbar {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  position: sticky;
  top: 0;
  z-index: 70;
  display: flex;
}

.navbar-username {
  display: flex;
}

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.navbar-logo {
  margin: 0;
  text-decoration: none;
  color: white;
  font-size: 22px;
}

.navbar-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

}

.navbar-item {
  margin-right: 20px;
  display: flex;

}

.navbar-link {
  color: #fff;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-link:hover {
  color: #aaa;
}


.navbar-button {
  background-color: #333;
  color: wheat;
  border: none;
  padding: 5px 10px;
  font-size: 15px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.navbar-button:hover {
  background-color: wheat;
  color: #333;
}

.navbar-link-admin {
  color: wheat;
  text-decoration: none;

}

.navbar-link-admin:hover {
  color: aquamarine;
}

.navbar-buchungen {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  border: 1px solid;
  padding: 10px;
}

.navbar-unconfirmed-orders {
  background-color: rgb(255, 21, 21);
  color: white;
  border-radius: 50%;
  padding: 5px 6px;
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  top: -13px;
  right: -15px;
  width: 12px;
  height: 13px;
  text-align: center;
}

.navbar-menu-icon {
  display: none;
}


@media screen and (max-width: 576px) {
  .navbar-links {
    display: none;
    /* По умолчанию скрываем меню на маленьких экранах */
    flex-direction: column;
    position: absolute;
    top: 60px;
    /* Выравниваем меню под навбаром */
    left: 0;
    right: 0;
    background-color: #333;
    z-index: 100;
    /* Поднимаем меню над основным контентом */
    opacity: 0.97;
  }

  .navbar-links.open {
    display: flex;
    /* Показываем меню при открытии */
  }

  .navbar-item {
    margin-right: 0;
    margin-bottom: 10px;
    /* Отступ между элементами меню */
    width: 100%;
    /* Растягиваем элементы на всю ширину */
    text-align: center;
    /* Выравниваем текст по центру */
    margin-left: 120px;
  }

  .navbar-menu-icon {
    display: flex;
    /* Отображаем иконку меню */
    align-items: center;
    margin-left: auto;
    /* Размещаем иконку в правом углу */
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 24px;
    /* margin-right: 30px; */

  }

  .navbar-menu-icon i {
    margin: 0;
  }

  .navbar-link {
    color: #fff;
    padding: 10px 0;
    font-size: 16px;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .navbar-link:hover {
    color: #aaa;
  }

  .navbar-logo {
    /* margin-left: 30px; */
  }

  .container {
    padding: 20px;
  }
}


@media screen and (min-width: 576px) and (max-width: 992px) {
  .navbar-links {
    display: none;
    /* По умолчанию скрываем меню на маленьких экранах */
    flex-direction: column;
    position: absolute;
    top: 60px;
    /* Выравниваем меню под навбаром */
    left: 0;
    right: 0;
    background-color: #333;
    z-index: 100;
    /* Поднимаем меню над основным контентом */
    opacity: 0.97;
  }

  .navbar-links.open {
    display: flex;
    /* Показываем меню при открытии */
  }

  .navbar-item {
    margin-right: 0;
    margin-bottom: 10px;
    /* Отступ между элементами меню */
    width: 100%;
    /* Растягиваем элементы на всю ширину */
    text-align: center;
    /* Выравниваем текст по центру */
    margin-left: 120px;
  }

  .navbar-menu-icon {
    display: flex;
    /* Отображаем иконку меню */
    align-items: center;
    margin-left: auto;
    /* Размещаем иконку в правом углу */
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 24px;
    margin-right: 30px;
  }

  .navbar-menu-icon i {
    margin: 0;
  }

  .navbar-link {
    color: #fff;
    padding: 10px 0;
    font-size: 16px;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .navbar-link:hover {
    color: #aaa;
  }

  .navbar-logo {
    margin-left: 30px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) {
  .navbar-logo {
    margin-left: 30px;
  }

 }