.footer {
    background-color: #F4F4F4;
    color: #333333;
    padding: 20px 0;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .logo-container {
    margin-bottom: 20px;
  }
  
  .footer-logo {
    height: 50px; /* Установите желаемую высоту для логотипа */
  }
  
  .links-container,
  .footer-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-links {
    margin-top: 10px;
    gap: 10px;
  }
  
  .footer-links li {
    margin-bottom: 10px;
  }
  
  .social-links a,
  .footer-links a {
    text-decoration: none;
    color: #333333;
  }

  .social-links {
    font-size: 1.125rem;
  }
  
  .social-links a:hover {
    color: gray;
  }
  