.ReactModal__Overlay{
    z-index: 99;
}



.gallery-container {
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
    padding-top: 60px;

  }
  
  .slick-slide img {
    width: 92%;
    height: auto;
  }

  .slick-track{
  padding-top: 50px;
  }

  .slider h1{
    color: white;
    font-size: 45px;
    margin: 0;
    text-shadow: -5px 6px 2px rgba(0,0,0,0.60); /* Используем text-shadow для тени текста */

  }

  .slider-title{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 30px;
    text-align: center;
  }

  .slider{
    padding-top: 50px;
    height: 730px;
    background-image: url('../../assets/wood.jpg');
    background-repeat: no-repeat;
    background-size: 100%; /* Растягиваем фоновое изображение на весь контейнер */
  }
  
  .slick-prev,
  .slick-next {
    font-size: 60px;
    line-height: 0;
    position: absolute;
    top: 50%;
    width: 120px; /* Увеличиваем ширину стрелок */
    height: 120px; /* Увеличиваем высоту стрелок */
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
  }
  
  
 
  
  .slick-prev:before {
    transform: translate(-50%, -50%) rotate(180deg);
  }
  
  .slick-prev {
    left: 0px !important;
  }
  
  .slick-next {
    right: 0px !important;
  }

  .galleryImg {
    width: 100px; /* Устанавливаем ширину изображения внутри слайда */
    height: auto; /* Поддерживаем соотношение сторон */
  }
  


  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Затемненный фон */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
  
  .modal-content {
    background-color: white;
    padding: 0; /* Убираем внутренние отступы */
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    width: 90%; /* Увеличиваем ширину модального окна */
    max-width: 1000px; /* Максимальная ширина модального окна */
    height: auto;
    max-height: 90%; /* Увеличиваем высоту модального окна */
    display: flex; /* Размещаем изображение по центру */
    justify-content: center;
    align-items: center;
  }
  
  
  .modal-content img {
    max-width: 100%;
    max-height: 100%; /* Делаем изображение занимающим всю высоту модального окна */
    object-fit: contain; /* Сохраняем пропорции изображения и вписываем его в контейнер */
  }
  
  
  
  .modal-close {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  
  .modal-close:before,
  .modal-close:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 2px;
    background-color: black;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  
  .modal-close:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  
  .modal-close:hover:before,
  .modal-close:hover:after {
    background-color: #3a50c9;
  }
  
  
  

  
  @media screen and (max-width: 1024px) {
    .gallery-container {
      width: 90%; /* Adjust as needed */
    }
  }
  
  @media screen and (max-width: 768px) {
    .gallery-container {
      width: 95%; /* Adjust as needed */
    }
  }
  

  @media screen and (max-width: 576px) {
    .slider {
      padding-top: 50px;
      height: auto;
      background-image: url('../../assets/wood.jpg');
      background-repeat: no-repeat;
      background-size: 250%;
      margin-top: 25px;
      }

      .slider h1 {
        font-size: 25px;
        margin: 0 15px;
    }
  }


  @media screen and (min-width: 576px) and (max-width: 1024px) {
    .slider {
      padding-top: 50px;
      height: auto;
      background-image: url('../../assets/wood.jpg');
      background-repeat: no-repeat;
      background-size: 100%;
  }
  }