/* Restaurants.css */
.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Это обеспечит, чтобы ваш контейнер занимал всю высоту видимой области страницы */
    padding-top: 60px;
  }
  
  .content {
    flex: 1; /* Это обеспечит, чтобы контент занимал всю доступную высоту контейнера */
    padding: 20px;
  }
  
  .restaurant-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Это сделает ваш контейнер гибким, с автоматическим размещением элементов в несколько колонок */
    gap: 50px; /* Расстояние между ресторанами */
  }
  
  .restaurant {
    background-color: #e2e2e2;
    color: black;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
  
  .restaurant-name {
    margin-top: 0;
    font-size: 22px;
  }
  
  .restaurant-address {
    font-size: 15px;
  }

  .mapboxgl-popup-content{
    background-color: #ffffff; /* Цвет фона для ресторана */

  }
  
  .mapboxgl-popup-content a{
    color: rgb(130, 107, 65); /* Цвет фона для ресторана */
    text-decoration: none;
  }

  .mapboxgl-popup-close-button{
    color: rgb(0, 0, 0);
  }

  .restaurant-hours {
    margin-bottom: 20px;
  }
  
  .map-container {
    height: 300px; /* Высота карты */
    border: 1px solid #ccc; /* Рамка вокруг карты */
    border-radius: 8px;
  }
  
  .footer {
    background-color: #333; /* Цвет фона для футера */
    color: #fff; /* Цвет текста */
    padding: 20px;
    text-align: center;
  }
  
  .map-container {
    position: relative; /* Позволяет позиционировать внутренние элементы относительно контейнера */
    height: 300px; /* Высота карты */
    border: 1px solid #ccc; /* Рамка вокруг карты */
    border-radius: 8px;
    overflow: hidden; /* Обрезает любые элементы, выходящие за пределы контейнера */
  }
  
/* Restaurants.css */

.marker {
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .star {
    color: #ccc; /* Цвет звезды */
  }
  
  .filled {
    color: gold; /* Цвет заполненной звезды */
  }
  
  .restaurantImg{
    width: 100%;
  }


  @media screen and (max-width: 576px) {

    .content h1{
      font-size: 20px;
    }

    .map-container {
      height: 200px;
    }


 }