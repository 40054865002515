.toggle-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.hinweis {
    font-size: 11px;
}

.aktualBtn {
    margin-left: 15%;
    width: 600px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 2px;
}

.panelBtnFilterFix2 {
    background: none;
    color: rgb(79, 128, 128);
    border: 2px solid rgb(79, 128, 128);
    padding: 8px 12px;
    cursor: pointer;
    transition: ease 0.5s;
    height: 37px;
    width: 600px;
}

.motoHover {
    transition: ease 0.5s all;
}

.motoHover:hover {
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    scale: 1.1;
}

.motoHover:disabled {
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    scale: 1;
}

/* .td1m, .td2m{
    transition: ease 0.5s all;
}

.td1m:hover{
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);

    background: rgb(79, 128, 128, 0.8);
    scale: 1.1;
    border-radius: 15px;
}

.td2m:hover{
    background: rgb(79, 128, 128, 0.7);
    scale: 1.1;
    border-radius: 15px;
} */


.columns {
    margin-top: 10px;
    display: flex;
    gap: 20px;
    align-items: center;
}

.anim {
    background: linear-gradient(180deg, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 176, 69, 1) 100%);
}

.column {
    height: 310px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.toggle-input {
    display: none;
}

.toggle-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 20px;
    border-radius: 20px;
    cursor: pointer;
    background-color: #323b31;
}

.toggle-inner,
.toggle-switched {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    transition: left 0.3s, opacity 0.3s;
    /* Добавляем opacity в анимацию */
}

.toggle-inner {
    left: 5px;
    background-color: red;
    box-shadow: 0 0 5px 1px rgba(255, 0, 0, 0.5);
}

.toggle-switched {
    left: 20px;
    background-color: rgb(0, 218, 0);
    opacity: 0;
    /* Начально гасим лампочку */
    box-shadow: 0 0 5px 1px rgba(0, 255, 0, 0.5);
}

.toggle-switch .toggle-input:checked+.toggle-label .toggle-inner {
    left: 20px;
    opacity: 0;
    /* При включенном состоянии гасим первую лампочку */
}

.toggle-switch .toggle-input:checked+.toggle-label .toggle-switched {
    display: block;
    opacity: 1;
    /* При включенном состоянии загораем вторую лампочку */
}

.toggle-switch.disabled .toggle-label {
    background-color: #ccc;
    /* Серый цвет для фона */
    cursor: not-allowed;
    /* Изменяем курсор на not-allowed */
}

.toggle-switch.disabled .toggle-switched {
    background-color: #666;
    /* Серый цвет для второй лампочки */
    box-shadow: none;
    /* Убираем тень для второй лампочки */
}


.order-modal {
    z-index: 102;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Черный полупрозрачный фон */
    display: flex;
    justify-content: center;
    align-items: center;
}

.order-details {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    max-height: 90%;
    /* Максимальная высота, чтобы содержимое было внутри окна */
    max-width: 90%;
    /* Максимальная ширина, чтобы содержимое было внутри окна */
    overflow: auto;
    /* Скролл для обоих направлений */
}

.close-button {
    margin-left: 96%;
    font-size: 24px;
    cursor: pointer;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
    outline: none;
    transition: color 0.3s ease;
    /* Плавный переход цвета */
}

.close-button:hover {
    color: darkred;
    /* Изменение цвета при наведении */
}


/* Дополнительные стили для toggle-switch и других элементов */




.table-container {
    font-family: Arial, sans-serif;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: center;
    font-size: 9px;
}

th {
    background-color: #f2f2f2;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

tr:hover {
    background-color: #dddddd;
}

.butContainer h3 {
    margin-top: 0;
}





.dot-green {
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
}

.dot-red {
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
}


.filter-buttons {
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    gap: 10px;
    border: 1px solid rgb(79, 128, 128);
    border-radius: 5px;
    padding: 5px;
    align-items: center;
    background-color: rgb(10 237 164 / 40%);
}

.filter-buttons-container {
    display: flex;
    gap: 7px;
}

.filterButtonsContainer {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.filter-buttons-container th {
    background-color: aquamarine;
    border: 1px solid rgb(79, 128, 128);
    border-radius: 5px;
}

.filterFlex {
    display: flex;
}

.filter-buttons label {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.filter-buttons input[type="checkbox"],
.filter-buttons input[type="radio"] {
    margin-right: 5px;
}

.filter-buttons label span {
    margin-left: 5px;
}

.filter-buttons button {
    background: none;
    color: rgb(79, 128, 128);
    border: 2px solid rgb(79, 128, 128);
    /* padding: 8px 12px; */
    cursor: pointer;
    transition: ease 0.5s;
    border-radius: 4px;
    height: 25px;
}

.panelBtnFilter {
    background: none;
    color: rgb(79, 128, 128);
    border: 2px solid rgb(79, 128, 128);
    padding: 8px 12px;
    cursor: pointer;
    transition: ease 0.5s;
    height: 37px;
}

.panelBtnFilterFix {
    background: none;
    color: rgb(79, 128, 128);
    border: 2px solid rgb(79, 128, 128);
    padding: 8px 12px;
    cursor: pointer;
    transition: ease 0.5s;
    height: 37px;
    width: 210px;
}

.panelBtnFilter2 {
    background: none;
    color: rgb(79, 128, 128);
    border: 2px solid rgb(79, 128, 128);
    cursor: pointer;
    transition: ease 0.5s;
    height: 35px;
    width: 35px;
    padding: 0;
}

.panelContainer {
    display: flex;
    gap: 20px;
    align-items: center;

}



.filter-buttons button:hover {
    background-color: rgb(79, 128, 128);
    color: aquamarine;
}

.panelBtnFilter:hover {
    background-color: rgb(79, 128, 128);
    color: aquamarine;
}

.panelBtnFilter:disabled:hover {
    background: none;
    color: #dddddd;
}

.panelBtnFilter:disabled {
    background-color: none;
    color: #dddddd;
    border-color: #dddddd;
}

.panelBtnFilterFix:hover {
    background-color: rgb(79, 128, 128);
    color: aquamarine;
}

.panelBtnFilterFix:disabled:hover {
    background: none;
    color: #a8a8a8;
}

.panelBtnFilterFix:disabled {
    background-color: none;
    /* color: #dddddd;
    border-color: #dddddd; */
    color: #a8a8a8;
    border-color: #a8a8a8;
}

.panelBtnFilter2:hover {
    background-color: rgb(79, 128, 128);
    color: aquamarine;
}

.panelBtnFilter2:disabled:hover {
    background: none;
    color: #a8a8a8;
}

.panelBtnFilter2:disabled {
    background-color: none;
    color: #a8a8a8;
    border-color: #a8a8a8;
}

.filter-buttons button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}


.confirmation-modal {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    z-index: 103 !important;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    /* z-index: 999; Поместите модальное окно поверх других элементов */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    /* Эффект тени */
}

.confirmation-modal p {
    margin-bottom: 10px;
}

.confirmation-modal button {
    width: 100px;
    margin-right: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.confirmation-modal button:last-child {
    margin-right: 0;
}

.confirmation-modal button:hover {
    background-color: #f0f0f0;
}


.orderMotorcycle {
    display: flex;
    gap: 40px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.radioContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.btn {
    /* width: 150px; */
    padding: 5px;
    background: #E30007;
    color: #ffffff;
    border: solid 3px;
    border-color: #E30007;
    transition: 0.5s ease;
    border-radius: 7px;
}

.btn2submit {
    width: 50%;
    padding: 5px;
    background: #E30007;
    color: #ffffff;
    border: solid 3px;
    border-color: #E30007;
    transition: 0.5s ease;
    border-radius: 7px;
    margin-top: 20px;
}

.butSection {

    display: flex;
}

.butContainer {
    border: 1px solid black;
    padding: 15px;
    border-radius: 10px;
    margin-top: 20px;
}

.btn:hover {
    background: none;
    color: #E30007;
}

.btn2submit:hover {
    background: none;
    color: #E30007;
}

.calendar {
    margin: 50px 50px;
}


.dark-calendar {
    background-color: #2e2e2e;
    color: #ffffff;
}

.dark-calendar .rbc-toolbar {
    background-color: #1a1a1a;
    color: #ffffff;
    border: none;
}

.dark-calendar .rbc-event {
    background-color: #3a3a3a;
    color: #ffffff;
}

.dark-calendar .rbc-month-view,
.dark-calendar .rbc-day-view,
.dark-calendar .rbc-week-view {
    background-color: #2e2e2e;
}

.dark-calendar .rbc-today {
    background-color: rgba(128, 128, 128, 0.3);
}

.dark-calendar .rbc-header {
    background-color: #1a1a1a;
    color: #ffffff;
}

.dark-calendar .rbc-time-header {
    background-color: #1a1a1a;
    color: #ffffff;
}

.dark-calendar .rbc-time-gutter {
    background-color: #2e2e2e;
}

.dark-calendar .rbc-event {
    color: #ffffff;
    background-color: #4a4a4a;
}

.dark-calendar .rbc-selected {
    background-color: #6a6a6a;
}

.dark-calendar .rbc-today {
    background-color: rgba(255, 255, 255, 0.2);
}

.dark-calendar .rbc-off-range {
    color: #555555;
}

.rbc-toolbar button {
    color: #e5e5e5;
}

.bestätigung {
    margin-right: 20px !important;
}

.panel {
    border: 3px solid rgb(237 237 237);
    padding: 21px;
    position: sticky;
    top: 82px;
    background: rgb(218 255 209);
    z-index: 70;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 40px;
}

.confirmed {
    color: green;
}

.not-confirmed {
    color: red;
}

.btn:disabled {
    color: gray;
    border-color: #919191;
    background: none;
}

.btn:disabled:hover {
    color: gray;
    border-color: #919191;
    background: none;
}

.headline {
    position: sticky;
    top: 169px;
    z-index: 69;
}


.tooltip {
    visibility: hidden;
    width: 100%;
    background-color: rgb(79, 128, 128);
    color: aquamarine;
    text-align: center;
    /* border-radius: 6px; */
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 0%;
    left: 0%;
    /* margin-left: -30px; */
    opacity: 0.8;

}

.tooltip2 {
    visibility: hidden;
    width: 100%;
    background-color: rgb(79, 128, 128);
    color: aquamarine;
    text-align: center;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 0%;
    left: 0%;
    opacity: 0.8;
}

/* .tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
} */

/* Показываем всплывающее окно при наведении */
td:hover .tooltip {
    visibility: visible;
}

td:hover .tooltip2 {
    visibility: visible;
}

.ja {
    border: 3px solid #54d754 !important;
    color: #54d754;
    transition: ease 0.3s;
    width: 55px !important;
    height: 50px !important;

}

.nein {
    border: 3px solid #e12c2c !important;
    color: #e12c2c;
    transition: ease 0.5s;
    width: 55px !important;
    height: 50px !important;
}

.ja:hover {
    background-color: #54d754 !important;
    color: white;
}

.nein:hover {
    background-color: #e12c2c !important;
    color: white;
}

/* Стиль контейнера лампочек */
.light-container {
    display: flex;
    align-items: center;
    /* background-color: rgb(19 36 31); */
    width: 57%;
    /* padding: 3px 2px 3px 6px; */
    border-radius: 8px;
    position: relative;
    padding: 10px;

}

/* Стиль отдельной лампочки */
.light {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin-right: 5px;
}

/* Стили для каждого цвета */
.blue-light {
    background-color: #0075FF;
    z-index: 20;
    animation: glow-blue 1.5s ease-in-out infinite alternate;
}

.red-light {
    background-color: #ff0000;
    z-index: 20;
    animation: glow-red 1.5s ease-in-out infinite alternate;
}

.yellow-light {
    background-color: #f7b600;
    z-index: 20;
    animation: glow-yellow 1.5s ease-in-out infinite alternate;
}

.green-light {
    background-color: #19ce19;
    z-index: 20;
    animation: glow-green 1.5s ease-in-out infinite alternate;
}

@keyframes glow-yellow {
    0% {
        box-shadow: 0 0 10px #ff8d00;
    }

    100% {
        box-shadow: 0 0 20px #ff8d00;
    }
}

@keyframes glow-green {
    0% {
        box-shadow: 0 0 10px #19ff19;
    }

    100% {
        box-shadow: 0 0 20px #19ff19;
    }
}


@keyframes glow-blue {
    0% {
        box-shadow: 0 0 10px #002fff;
    }

    100% {
        box-shadow: 0 0 20px #003cff;
    }
}

@keyframes glow-red {
    0% {
        box-shadow: 0 0 10px #ff2200;
    }

    100% {
        box-shadow: 0 0 20px #ff0066;
    }
}

.dark-red-light {
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: #41000b;

}


.dark-yellow-light {
    background-color: #643600;
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin-right: 5px;
    left: 24px;
}

.dark-green-light {
    background-color: #005e00;
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin-right: 5px;
    left: 42px;
}

.ampel {
    width: 95px;
}


td p {
    line-height: 3px;
}

.auftrag {
    width: 14%;
}

.approve {
    margin-left: 15px;
    font-size: 9px;
}

.panelStatus {
    width: 2%;
}

.panelMot {
    width: 10%;
}

.panelDate {
    width: 10%;
}

.panelName {
    width: 7%;

}

.panelNo {
    width: 2%;
}

.panelBtn2 {
    background: none;
    width: 32px;
    padding: 4px 0;
    color: #0075FF;
    border: none;
    border-style: solid;
    transition: all ease 0.5s;
}

.panelBtn2:hover {
    background-color: #0075FF;
    color: white;
    /* border: none; */
    border-color: #0075FF;
}

.panelBtn2:disabled {
    background: none;
    border-color: rgb(214, 214, 214);
    color: rgb(214, 214, 214);
}

.panelBtn2:disabled:hover {
    background: none;
    border-color: rgb(214, 214, 214);
    color: rgb(214, 214, 214);
}

.panelBtn {
    background: none;
    width: 150px;
    padding: 4px;
    color: #0075FF;
    border: none;
    border-style: solid;
    transition: all ease 0.5s;
}

.panelBtn:hover {
    background-color: #0075FF;
    color: white;
    /* border: none; */
    border-color: #0075FF;
}

.panelBtn:disabled {
    background: none;
    border-color: rgb(214, 214, 214);
    color: rgb(214, 214, 214);
}

.panelBtn:disabled:hover {
    background: none;
    border-color: rgb(214, 214, 214);
    color: rgb(214, 214, 214);
}

.panelBtnCOntainer {
    display: flex;
    justify-content: center;
}

.panelIcon {
    display: flex;
    align-items: center;
}

th {
    text-align: center;
}




.custom-btn {
    width: 130px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
        7px 7px 20px 0px rgba(0, 0, 0, .1),
        4px 4px 5px 0px rgba(0, 0, 0, .1);
    outline: none;
}

/* 12 */
.btn-12 {
    position: relative;
    right: 20px;
    bottom: 20px;
    border: none;
    box-shadow: none;
    width: 130px;
    height: 40px;
    line-height: 42px;
    -webkit-perspective: 230px;
    perspective: 230px;
}

.btn-12 span {
    background: rgb(0, 172, 238);
    background: linear-gradient(0deg, rgba(0, 172, 238, 1) 0%, rgba(2, 126, 251, 1) 100%);
    display: block;
    position: absolute;
    width: 130px;
    height: 40px;
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
        7px 7px 20px 0px rgba(0, 0, 0, .1),
        4px 4px 5px 0px rgba(0, 0, 0, .1);
    border-radius: 5px;
    margin: 0;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s;
}

.btn-12 span:nth-child(1) {
    box-shadow:
        -7px -7px 20px 0px #fff9,
        -4px -4px 5px 0px #fff9,
        7px 7px 20px 0px #0002,
        4px 4px 5px 0px #0001;
    -webkit-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    transform: rotateX(90deg);
    -webkit-transform-origin: 50% 50% -20px;
    -moz-transform-origin: 50% 50% -20px;
    transform-origin: 50% 50% -20px;
}

.btn-12 span:nth-child(2) {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: 50% 50% -20px;
    -moz-transform-origin: 50% 50% -20px;
    transform-origin: 50% 50% -20px;
}

.btn-12:hover span:nth-child(1) {
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
        7px 7px 20px 0px rgba(0, 0, 0, .1),
        4px 4px 5px 0px rgba(0, 0, 0, .1);
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    transform: rotateX(0deg);
}

.btn-12:hover span:nth-child(2) {
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
        7px 7px 20px 0px rgba(0, 0, 0, .1),
        4px 4px 5px 0px rgba(0, 0, 0, .1);
    color: transparent;
    -webkit-transform: rotateX(-90deg);
    -moz-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
}


.viewBtnContainer {
    display: flex;
    /* justify-content: center; */
    /* margin-top: 20px; */
    gap: 20px;
    align-items: center;
}

.modalBtnContainer {
    display: flex;
    gap: 50px;
    justify-content: center;
}


.modalBtnContainer2 {
    display: flex;
    flex-direction: column;
    gap: 6px;
    border-left: 2px solid rgb(0, 117, 201);
    border-right: 2px solid rgb(0, 117, 201);
    padding: 0 15px 0 15px;
    align-items: center;
}


.matching-orders {
    display: flex;

}

.matching-orders li {
    cursor: pointer;
}

.modalIn p {
    margin: 2px;
}

.modalInfoHead {
    display: flex;
    justify-content: space-around;
}

.panelIcon2 {
    display: flex;
    align-items: center;
    height: 20px;
}

.approve2 {
    margin: 0;
}







.radioLine {
    margin-top: 10px;
    appearance: none;
    /* Убираем стандартный внешний вид */
    -webkit-appearance: none;
    /* Для WebKit браузеров */
    -moz-appearance: none;
    /* Для Firefox */
    height: 6px;
    /* Высота радиокнопки */
    border-radius: 5px;
    /* Закругленные края для создания полоски */
    border: 1px solid #000000;
    /* Граница полоски */
    outline: none;
    /* Убираем контур фокуса */
    /* cursor: pointer; */
    /* Указатель при наведении */
    position: relative;
    /* Позиционирование */
    transition: background-color 0.3s ease;
    /* Плавное изменение цвета фона */
}

.radioLine:checked {
    background-color: #3da102;
    /* Цвет фона при выборе */
}

/* Стили для визуальной полоски */
.radioLine::before {
    content: '';
    /* Создаем пустой контент */
    display: block;
    /* Блочный элемент */
    /* width: 50%; Ширина полоски (можно регулировать) */
    height: 100%;
    /* Высота полоски (занимает весь контейнер) */
    background-color: #005e8d;
    /* Цвет полоски */
    position: absolute;
    /* Абсолютное позиционирование */
    top: 0;
    /* Верхний край */
    left: 0;
    /* Левый край */
    border-radius: 5px;
    /* Закругленные края */
    transition: width 0.3s ease;
    /* Плавное изменение ширины */
}

.radioLine:checked::before {
    width: 100%;
    /* Развертываем полоску при выборе */
}


.radioLine:disabled {
    background-color: none;
    /* Цвет полоски */
    /* Цвет полоски */
    border: none;
}

.radioLine2 {
    /* margin-top: 10px; */
    appearance: none;
    /* -webkit-appearance: none; Для WebKit браузеров */
    -moz-appearance: none;
    /* Для Firefox */
    height: 5px;
    /* Высота радиокнопки */
    width: 90%;
    border-radius: 5px;
    /* Закругленные края для создания полоски */
    border: 1px solid #000000;
    /* Граница полоски */
    outline: none;
    /* Убираем контур фокуса */
    /* cursor: pointer; */
    /* Указатель при наведении */
    position: relative;
    /* Позиционирование */
    transition: background-color 0.3s ease;
    /* Плавное изменение цвета фона */
    /* margin-right: 15px; */
}

.radioLine2:checked {
    background-color: #3da102;
    /* Цвет фона при выборе */
}

/* Стили для визуальной полоски */
.radioLine2::before {
    content: '';
    /* Создаем пустой контент */
    display: block;
    /* Блочный элемент */
    /* width: 50%; Ширина полоски (можно регулировать) */
    height: 100%;
    /* Высота полоски (занимает весь контейнер) */
    background-color: #005e8d;
    /* Цвет полоски */
    position: absolute;
    /* Абсолютное позиционирование */
    top: 0;
    /* Верхний край */
    left: 0;
    /* Левый край */
    border-radius: 5px;
    /* Закругленные края */
    transition: width 0.3s ease;
    /* Плавное изменение ширины */
}

.radioLine2:checked::before {
    width: 100%;
    /* Развертываем полоску при выборе */
}


.radioLine2:disabled {
    background-color: none;
    /* Цвет полоски */
    border: none;
}



.modalBlock {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

.modalBlock div {
    display: flex;
    flex-direction: column;
    padding: 5px;
    padding-right: 15px;
}

.modalBlock p {
    margin: 2px;
}

.modalBlock>div:first-child {
    border-right: 1px solid black;
}


.modalInfoBlock {
    display: flex;
    justify-content: space-around;

}


.order-details h2 {
    margin: 0;
}


.modalInfoHead {
    margin-top: 10px;
}

.infoLeft {
    width: 50%;
}

.blockLeft div {
    display: flex;
    justify-content: space-between;
}

.blockLeft {
    width: 45%;

}

.blockLeft p {
    margin: 3px;
    font-size: 14px;
}

.matching-orders h5 {
    margin: 0;
}

.pdfBtnContainer{
    gap: 10px;
    display: flex;
}









.pdf-modal {
    position: fixed;
    /* Изменено на fixed */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 900;
    /* Приведение модального окна на передний план */
}

.pdf-modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 50%;
    height: 60%;
    overflow: auto;
    /* Добавить прокрутку, если контент выходит за рамки */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
}















/* Styles for modal */
.modal5 {
    position: fixed;
    z-index: 1000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
}

/* Styles for the modal overlay */
.modal5-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
}

/* Styles for the close button */
.modal5 button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal5 button:hover {
    background-color: #0056b3;
}


















@media screen and (min-width: 576px) and (max-width: 1024px) {

    .filterFlex {
        display: flex;
        flex-direction: column;
    }

    .filter-buttons-container {
        display: flex;
        gap: 7px;
        flex-direction: column;
    }

    .panelContainer {
        display: flex;
        gap: 20px;
        align-items: center;
        flex-direction: column;
    }

    .panel {
        border: 3px solid rgb(237 237 237);
        padding: 21px;
        position: sticky;
        top: 82px;
        background: rgb(218 255 209);
        z-index: 70;
        display: flex;
        align-items: center;
        gap: 10px;
        height: auto;
    }



    .responsive-table {
        display: grid;
        grid-template-columns: repeat(2, minmax(300px, 1fr));
        gap: 20px;
    }

    .responsive-table thead {
        display: none;
    }

    .responsive-table tbody {
        display: contents;
        /* Для поддержки IE можно использовать display: block и flexbox */
    }

    .responsive-table tr {
        display: flex;
        flex-direction: column;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        padding: 15px;
        margin-bottom: 15px;
    }

    .responsive-table th,
    .responsive-table td {
        display: block;
        text-align: left;
        border: none;
    }

    .responsive-table td::before {
        content: attr(data-label);
        font-weight: bold;
        margin-bottom: 5px;
        display: inline-block;
        min-width: 120px;
        /* Настраиваемый размер меток */
    }



}




@media screen and (max-width: 576px) {

    .confirmation-modal div {
        display: flex;
    }

    tr:hover {
        background-color: #caf6f99e;
    }

    .ampel {
        width: 95%;
    }

    .responsive-table,
    .responsive-table thead,
    .responsive-table tbody,
    .responsive-table th,
    .responsive-table td,
    .responsive-table tr {
        display: block;
    }

    .responsive-table thead tr {
        display: none;
    }

    .light-container {
        width: 5%;
    }

    .tdName p {
        font-size: 16px;
    }

    tr::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: rgb(79, 128, 128);
        /* margin: 15px 0; */
    }

    .tdId::before {
        content: 'No.' !important;
        font-size: 17px;
        align-self: end;
    }

    .ampel::before {
        content: 'Status:' !important;
        font-size: 17px;
        align-self: end;
    }


    .td1m::before {
        content: '1 Motorrad:' !important;
        font-size: 17px;
        align-self: end;
    }




    .td2m::before {
        content: 'Alternativ:' !important;
        font-size: 17px;
        align-self: end;
    }

    .tdName::before {
        content: 'Name, tel:' !important;
        font-size: 17px;
        align-self: end;
    }

    .tdDateVon::before {
        content: 'von' !important;
        font-size: 17px;
        align-self: end;
    }

    .tdDateBis::before {
        content: 'bis' !important;
        font-size: 17px;
        align-self: end;
    }


    .order-details h2 {
        margin: 0;
        font-size: 16px;
    }


    .modalIn p {
        font-size: 10px;
    }

    .modalIn {
        width: 160px !important;
        height: 80px !important;
    }

    .orderMotorcycle {
        gap: 10px;
    }


    .blockLeft p {
        font-size: 11px;
    }

    .panelIcon2 p {
        font-size: 13px;
    }

    .approve2 {
        font-size: 13px;
    }

    .modalInfoHead {
        font-size: 13px;
    }

    .modalInfoHead div:first-child {
        /* padding-right: 10px; */
    }

    .matching-orders tr {
        font-size: 11px;
    }

    .modalBtnContainer2 {
        display: flex;
        flex-direction: column;
        gap: 6px;
        border-left: 2px solid rgb(0, 117, 201);
        border-right: 2px solid rgb(0, 117, 201);
        padding: 0 15px 0 15px;
        align-items: center;
    }

    .responsive-table tr {
        /* margin-bottom: 1rem; */
        border: none;
    }

    .responsive-table td {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem;
        font-size: 20px;
    }

    .responsive-table td::before {
        content: attr(data-label);
        font-weight: bold;
        margin-right: 1rem;
    }

    .calendar {
        margin: 0;
        margin-top: 20px;
    }

    .filterFlex {
        display: flex;
        flex-direction: column;
    }

    .filter-buttons-container {
        display: flex;
        gap: 7px;
        flex-direction: column;
    }

    .panelContainer {
        display: flex;
        gap: 20px;
        align-items: center;
        flex-direction: column;
    }

    .panel {
        border: 3px solid rgb(237 237 237);
        padding: 21px;
        position: sticky;
        top: 70px;
        background: rgb(218 255 209);
        z-index: 69;
        display: flex;
        align-items: center;
        gap: 10px;
        height: auto;
        flex-direction: column;
    }


    .aktualBtn {
        margin-left: 0;
        width: 300px;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 2px;
    }

    .motoHover p {
        font-size: 14px;
    }

    .tdDateVon div,
    .tdDateBis div {
        font-size: 14px;
    }

    .approve {
        font-size: 14px;
    }

    .butSection {
        flex-direction: column;
    }


    .pdf-modal-content {
        width: 85%;
    }

    .hinweis {
        font-size: 11px;
    }

    .modal5 {
        width: 85%;
    }

    .confirmation-modal {
        width: 80%;
    }

    .pdf-modal-content {
       gap: 20px;
    }

    .pdfBtnContainer{
        gap: 10px;
        flex-direction: column;
    }

    .column p{
        font-size: 11px;
    }

    .columns {
        gap: 6px;
    }


}


@media screen and (min-width: 1024px) and (max-width: 1300px) {


    .filterFlex {
        display: flex;
        flex-direction: column;
    }

    .filter-buttons-container {
        display: flex;
        gap: 7px;
        flex-direction: column;
    }

    .panelContainer {
        display: flex;
        gap: 20px;
        align-items: center;
        flex-direction: column;
    }

    .panel {
        border: 3px solid rgb(237 237 237);
        padding: 21px;
        position: sticky;
        top: 82px;
        background: rgb(218 255 209);
        z-index: 70;
        display: flex;
        align-items: center;
        gap: 10px;
        height: auto;
    }



    .responsive-table {
        display: grid;
        grid-template-columns: repeat(3, minmax(300px, 1fr));
        gap: 20px;
    }

    .responsive-table thead {
        display: none;
    }

    .responsive-table tbody {
        display: contents;
        /* Для поддержки IE можно использовать display: block и flexbox */
    }

    .responsive-table tr {
        display: flex;
        flex-direction: column;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        padding: 15px;
        margin-bottom: 15px;
    }

    .responsive-table th,
    .responsive-table td {
        display: block;
        text-align: left;
        border: none;
    }

    .responsive-table td::before {
        content: attr(data-label);
        font-weight: bold;
        margin-bottom: 5px;
        display: inline-block;
        min-width: 120px;
        /* Настраиваемый размер меток */
    }

}