.container1 {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background: blue; */
    width: 220px;
    height: 125px;
    color: black;
    font-family: 'droid Sans', sans-serif;
}

.datenText2{
    display: none;
}

.bike-body {
    width: 250px;
    height: 108px;
    /* background: blue; */
    position: relative;
    left: -20px;
    top: 3px;
}

.bike-body .seat {
    width: 30px;
    height: 30px;
    background: radial-gradient(circle at right top, rgba(248, 80, 50, 0) 0%, rgba(241, 111, 92, 0) 50%, rgba(246, 41, 12, 0) 51%, rgba(242, 45, 19, 0) 64%, rgba(67, 75, 89, 1) 65%, rgba(67, 75, 89, 1) 71%, rgba(67, 75, 89, 1) 100%);
    background-position: center;
    background-repeat: no-repeat;
    transform: rotateZ(-40deg);
    position: absolute;
    top: 64px;
    left: 65px;
}

.bike-body .seat:before {
    content: '';
    display: block;
    width: 9px;
    height: 35px;
    position: relative;
    top: -2px;
    left: -4px;
    background: #434B59;
    border-radius: 5px;
}

.bike-body .seat:after {
    content: '';
    display: block;
    width: 35px;
    height: 9px;
    position: relative;
    top: -10px;
    left: -4px;
    background: #434B59;
    border-radius: 5px;
}

.bike-body .cover {
    width: 75px;
    height: 30px;
    background: #F4492C;
    position: absolute;
    top: 55px;
    left: 105px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-radius: 110px 20px 15px 5px / 50px 20px 20px 5px;
    z-index: 11;
    box-shadow: inset -5px -2px 0px 2px rgba(0, 0, 0, 0.2);
}

.bike-body .lamp {
    width: 25px;
    height: 25px;
    background: linear-gradient(90deg, rgba(67, 75, 89, 1) 65%, #FFDD4D 35%);
    border-radius: 70px 60px 60px 50px / 40px 60px 60px 15px;
    position: relative;
    top: 60px;
    right: -185px;
    /* transform: rotateZ(-5deg); */
}

.bike-body .motor {
    width: 75px;
    height: 45px;
    /* background: blue; */
    position: absolute;
    left: 90px;
    top: 90px;
}

.bike-body .motor .part-1 {
    width: 35px;
    height: 45px;
    /* background: yellow; */
    display: inline-block;
}

.bike-body .motor .part-1 .part-1-top {
    width: 40px;
    height: 20px;
    background: linear-gradient(135deg, transparent 10px, #F5492C 0);
    border-radius: 0px 5px 5px 5px;
    box-shadow: inset -2px -2px 0px 1px rgba(0, 0, 0, 0.2);
}

.bike-body .motor .part-1 .part-1-bottom {
    width: 35px;
    height: 17px;
    background: #829399;
    position: relative;
    top: 5px;
    border-radius: 5px;
}

.bike-body .motor .part-1 .part-1-bottom:after {
    content: '';
    display: block;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: #829399;
    border: 3px solid #A2B1B7;
    position: relative;
    left: 13px;
    top: -3px;
}

.bike-body .motor .part-2 {
    width: 30px;
    height: 45px;
    /* background: yellow; */
    display: inline-block;
    animation: shake 0.1s infinite;
}

.bike-body .motor .part-2 .part-2-base {
    width: 25px;
    height: 33px;
    background: #A4B4BA;
    margin: auto;
    position: relative;
    top: 5px;
    left: 5px;
    border-radius: 15px;
}

.bike-body .motor .part-2 .part-2-base .line {
    position: relative;
    margin: auto;
    width: 105%;
    left: -2.5%;
    top: -2px;
    height: 4px;
    background: #829399;
    margin-bottom: 6px;
    border-radius: 3px;
}

.bike-body .motor .part-2 .part-2-base .line:nth-child(1) {
    height: 6px;
    background: #434B59;
    top: 0;
}

.bike-body .motor .part-2 .part-2-base .line:nth-child(1):before {
    content: '';
    display: block;
    width: 20px;
    height: 4px;
    background: #434B59;
    margin: auto;
    position: relative;
    top: -3px;
    border-radius: 5px;
}

.bike-body .motor .part-2 .part-2-base .line:nth-child(4) {
    width: 60%;
}

.bike-body .front {
    position: absolute;
    width: 10px;
    height: 100px;
    background: #AABABF;
    transform: rotateZ(-30deg);
    right: 53px;
    top: 40px;
    border-radius: 7px;
    z-index: 10;
}

.bike-body .front:before {
    content: '';
    display: block;
    width: 25px;
    height: 6px;
    background: rgba(67, 75, 89, 1);
    border-radius: 5px;
    transform: rotateZ(30deg);
    position: relative;
    left: -18px;
    top: -7px;
}

.bike-body .front:after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: rgba(67, 75, 89, 1);
    position: absolute;
    top: -5px;
}

.bike-body .back {
    position: absolute;
    width: 120px;
    box-sizing: content-box;
    background: transparent;
    border: 10px solid #AABABF;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    bottom: -40px;
    left: 30px;
    z-index: 10;
}

.bike-body .back:before {
    content: '';
    display: block;
    width: 75px;
    height: 13px;
    background: #AABABF;
    position: absolute;
    left: 123px;
    top: 2px;
    transform-origin: 0px;
    transform: rotateZ(-70deg);
    border-radius: 0px 0px 10px 0px;
}

.bike-body .back:after {
    content: '';
    display: block;
    position: absolute;
    width: 80px;
    height: 9px;
    background: transparent;
    border: 10px solid #AABABF;
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-radius: 0px 10px 0px 0px;
    transform: skewX(20deg);
    top: -18px;
    left: -50px;
}

.tire {
    position: absolute;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: #ccc;
    border: 15px solid white;
    box-shadow: 0px 0px 0px 10px #454D5B;
}

.tire:before {
    content: '';
    display: block;
    width: 50px;
    height: 50px;
    border: 10px solid transparent;
    border-top-color: #F5492C;
    border-radius: 50%;
    background: transparent;
    position: relative;
    top: -30px;
    left: -25px;
}

.tire:after {
    content: '';
    display: block;
    width: 50px;
    height: 50px;
    border: 4px solid transparent;
    border-top-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    background: transparent;
    position: relative;
    top: -93px;
    left: -19px;
    z-index: 9;
}

.tire:nth-child(2) {
    right: 0;
}

.tire:nth-child(2):before,
.tire:nth-child(2):after {
    transform: rotateZ(-20deg);
}

.smoke {
    width: 15px;
    height: 15px;
    background: black;
    border-radius: 50%;
    position: absolute;
    top: 120px;
    left: -50px;
    animation: fly 3s infinite;
}

.smoke:before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: black;
    position: relative;
    right: -12px;
    top: 5px;
}

@keyframes shake {

    0%,
    100% {
        transform: rotateZ(0deg);
    }

    25% {
        transform: rotateZ(-5deg);
    }

    50% {
        transform: rotateZ(5deg);
    }
}

@keyframes fly {
    0% {
        opacity: 1;
    }

    35%,
    100% {
        opacity: 0;
        top: 100px;
        left: -70px;
    }
}












































.error-message {
    color: rgb(255, 255, 255);
    margin-left: 48%;
    margin-bottom: 5px;
    padding: 5px;
    background-color: rgb(255, 114, 114);
    display: flex;
    justify-content: center;
    border-radius: 5px;
    font-size: 13px;
}

.error-message1 {
    color: rgb(255, 255, 255);
    margin-bottom: 5px;
    padding: 5px;
    background-color: rgb(255, 114, 114);
    display: flex;
    justify-content: center;
    border-radius: 5px;
    font-size: 13px;
}

.firework {
    position: relative;
    width: 100vw;
    /* height: 100vh; */
    height: 125px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.infoPage {
    height: 650px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#touren {
    padding-top: 70px;
}

#btn1 {
    visibility: visible;
}

#btn2 {
    visibility: hidden;
}

.hidden {
    display: none;
}

.pdfContainer {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    gap: 20px;
}

.lastFormElement {
    display: flex;
    justify-content: center;
}

.details {
    display: flex;
    justify-content: center;
}

.detailsContainer {
    display: flex;
    justify-content: center;
    gap: 150px;
}

.alternativ {
    margin-top: 40px;
}

.motoDetailsContainer {
    padding: 0 20px;
}

.motoDetailsContainer h3 {
    margin-bottom: 0;
    font-size: 16px;
}

.motoDetailsContainer h2 {
    font-size: 20px;
}

.details_p {
    margin-top: 3px;
    margin-right: 3px;
}

.details_time {
    display: flex;
    /* justify-content: space-between; */

}


.personPart {
    padding-left: 35px;

}



.details_time p {
    margin: 2px;
    margin-right: 5px !important;
}

.details_price {
    margin: 25px 0;

}

.details_price p {
    margin: 2px;
    font-size: 14;
}

.motoDetails {
    height: fit-content;
    position: sticky;
    top: 90px;
    width: 400px;
    background-color: #C7EBFC;
    padding: 4px 15px 15px;
    color: #211818;
    border-radius: 10px;
    transition: top 0.5s ease;
    /* Анимация для свойства top с продолжительностью 0.5 секунды и плавностью */
}








.motoDetailsLeft {
    width: 300px;
}

.motoDetailsRight {
    width: 300px;
}

.price {
    display: flex;
    justify-content: center;
    gap: 150px;
}

.priceLeft {
    width: 300px;
}

.priceRight {
    width: 300px;
}

.detailsLeft {
    width: 50%;
}

.detailsRight {
    width: 50%;
}








.form {
    margin: 10px 80px 0;
    display: flex;
    justify-content: center;
    /* align-items: flex-start; */
    /* width: 1000px; */
    gap: 80px;
}




.firstForm {
    display: flex;
    width: 100%;
}





.formContainer {
    padding: 1rem;
    /* Уменьшил значение padding */
}



.formLeft {
    display: flex;
}

.formRight {
    /* margin-top: 50px; */
    max-width: 430px;
    /* padding-top: 3px; */

}

.formRight .field label {
    font-size: 14px;
}

.formRight .field input {
    width: 50%;
}

.phoneInput {
    width: 50%;
}

.form-control {
    width: 100% !important;
    height: 30px !important;
}

.react-tel-input {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    position: relative;
    width: 52% !important;
}

.field .react-datepicker__input-container input {
    margin-right: 40px;
    width: 92.5%;
}

.form select {
    width: 52.5% !important;
}

.form h2,
formIn h2 {
    margin: 30px 0;
}

.form label,
.formIn label {
    display: block;
    margin-bottom: 0.3rem;
    /* Уменьшил значение margin-bottom */
    font-weight: bold;
    font-size: 15px;
    /* Уменьшил размер текста метки */
}

.form input,
.form select,
.formIn input,
.formIn select {
    width: calc(100% - 1rem);
    /* Уменьшил ширину элементов ввода */
    padding: 0.3rem;
    /* Уменьшил значение padding */
    margin-bottom: 0.3rem;
    /* Уменьшил значение margin-bottom */
    border: 1px solid #ccc;
    border-radius: 3px;
    /* Уменьшил радиус границы */
    font-size: 0.9rem;
    /* Уменьшил размер текста */
}

.form input[type="checkbox"] {
    width: auto;
    margin-right: 0.3rem;
    /* Уменьшил значение margin-right */
}

.form input[type="submit"] {
    background-color: #007bff;
    color: #fff;
    padding: 0.3rem 0.7rem;
    /* Уменьшил значение padding */
    border: none;
    border-radius: 3px;
    /* Уменьшил радиус границы */
    cursor: pointer;
    font-size: 0.9rem;
    /* Уменьшил размер текста */
}

.form input[type="submit"]:hover {
    background-color: #0056b3;
}

.form select {
    appearance: none;
}

.form select::-ms-expand {
    display: none;
}

#agreements {
    width: auto;
}

#agreements1 {
    width: 13px;
}

/* Adjust styles for radio buttons */
.form label input[type="radio"] {
    margin-right: 0.3rem;
    /* Уменьшил значение margin-right */
}

/* Styling for additional elements */
.formLeft>div,
.formRight>div {
    margin-bottom: 0.5rem;
    /* Уменьшил значение margin-bottom */
}

.submitBtn {
    margin-top: 30px;
    width: 100%;
    /* background-color: #007bff; */
    background: none;
    color: #007bff;
    padding: 0.5rem 1rem;
    border: 3px solid #007bff;
    /* border-radius: 5px; */
    cursor: pointer;
    font-size: 1rem;
    transition: ease 0.3s;
}

.submitBtn:hover {
    background-color: #007bff;
    color: white;
}


.tarif input {
    width: 3%;
    margin-top: 10px;
}

.tarif h3 {
    margin-top: 40px;
}

.tarif label {
    /* display: flex; */
    gap: 10px;
}

.dpContainer {
    display: flex;
}

.timePicker,
.timePicker1 {
    width: 17%;
}

.timePicker1 {
    border-left: none;
    border-radius: 0 3px 3px 0 !important;
}


.timePickerContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 21px;
}






.label_a {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.radio_a {
    display: none;
}

.radio_a+p {
    display: flex;
    gap: 20px;
    line-height: 24px;
}

.radio_a+p::before {
    content: 'A';
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid black;
    background-color: white;
    line-height: 20px;
    /* Расположение текста по вертикали */
    text-align: center;
    /* Расположение текста по горизонтали */
}

.radio_a:checked+p::before {
    background-color: red;
    color: white;
}

.radio_a:checked+p::after {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
}

.radio_a:disabled:checked+p::before {
    background-color: rgb(129, 129, 129);
    color: rgb(236, 236, 236);
}








.label_b {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.radio_b {
    display: none;
}

.radio_b+p {
    display: flex;
    gap: 20px;
    line-height: 24px;
}

.radio_b+p::before {
    content: 'B';
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid black;
    background-color: white;
    line-height: 21px;
    /* Расположение текста по вертикали */
    text-align: center;
    /* Расположение текста по горизонтали */
}

.radio_b:checked+p::before {
    background-color: red;
    color: white;
}

.radio_b:checked+p::after {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
}

.radio_b:disabled:checked+p::before {
    background-color: rgb(129, 129, 129);
    color: rgb(236, 236, 236);
}








.label_c {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.radio_c {
    display: none;
}

.radio_c+p {
    display: flex;
    gap: 20px;
    line-height: 24px;
}

.radio_c+p::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid black;
    background-color: white;
    line-height: 17px;
    /* Расположение текста по вертикали */
    text-align: center;
    /* Расположение текста по горизонтали */
}

.radio_c:checked+p::before {
    background-color: red;
    color: white;
}

.radio_c:checked+p::after {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
}


.radio_c:disabled:checked+p::before {
    background-color: rgb(129, 129, 129);
    color: rgb(236, 236, 236);
}


.onepixel div {
    margin-bottom: 2px;
}


.wahl {
    display: flex;
    margin-left: 20px;
    align-items: center;
}

.label1 {
    margin-right: 35px;
}

.label2 {
    margin-right: 17px;
}



.wahl select {
    width: 43%;
    text-align: center;
}

.zusatz {
    display: flex;
}

.zusatz_info,
.zusatz_info2 {
    width: 300px;
    margin: 12px 0 10px;

}

.zusatz_info h5,
.zusatz_info2 h5 {
    margin: 5px 0;
}

.zusatz_info p {
    margin-left: 35px;
    font-size: 14px;
}

.zusatz_info p {
    margin-left: 35px;
    font-size: 14px;
}

.zusatz_info2 p {
    font-size: 14px;
}

.zusatz input[type="checkbox"] {
    display: none;
}

.zusatz input[type="checkbox"]+label::before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid black;
    background-color: white;
    margin-right: 5px;
}

.zusatz input[type="checkbox"]:checked+label::before {
    background-color: red;
}

.zusatz input[type="checkbox"]:disabled:checked+label::before {
    background-color: rgb(129, 129, 129);
    color: rgb(236, 236, 236);
}

.zusatz_label {
    display: flex !important;
    gap: 10px;
}


.dpContainer {
    margin: 15px auto 10px;
}

.field {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.field1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

.field input {
    width: 70%;
}

.field label {
    font-size: 15px;
}

.contactInfo {
    margin: 50px 270px 50px;

    /* 50px 0 20px; */
}

.btn {
    /* margin: 10px auto; */
    width: 100%;


}

.btn1 {

    width: 100%;
    padding: 5px;
    background: #E30007;
    color: #ffffff;
    /* border: solid 3px; */
    /* border-color: #ff0000; */
    border: none;
    transition: 0.5s ease;

}

.btnContainer {
    margin-top: 10px;
    /* display: flex; */
    width: 100%;
}

.motorrad h3 {
    margin-top: 2px;
}

.motorradContainer p {
    margin-bottom: 5px;
}

.zeitContainer {}

.details_time p {
    margin: 0;
}


.endPrice {
    font-size: 20px;
}

.alternativ p {
    margin-bottom: 7px;

}

.alternativ h3 {
    margin-top: 0;
}

.react-datepicker__input-container {
    width: 103%;

}

.onepixel {
    padding-top: 0.5px;
}

.date {
    border-right: none;
}

.timePicker1 input {
    border-left: none;
    border-radius: 0 3px 3px 0 !important;
}

.datePickerIn input {
    border-right: none;
    border-radius: 3px 0 0 3px !important;
}

.datePicker {
    display: flex;
}

.datePickerIn {}

#personDaten {
    height: 729px;
    margin-top: 20px;
    width: 100%;
}

#personDaten h3 {
    /* margin-top: 160px; */
    padding-top: 40px;

}

.personDaten2 {
    /* margin-top: 50px; */
}


.personContainer {
    /* text-align: center; */
    height: 600px;
    background-color: #d6eff5;
    /* border-radius: 10px; */
    /* padding: 10px 472px 180px; */
    /* margin: 0px -390px; */
    height: 490px;
    /* padding-left: 50%; */
    /* padding: 5px 478px 178px; */
    padding: 5px 240px 178px;
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
}

.contactContainer {
    margin: 70px 0 90px;
    width: 520px;
}

.persDat {
    width: 100%;
    display: flex;
    /* gap: 30px; */
}

.persDat p {
    padding-left: 35px;
}


.contactInfo2 {
    /* padding-left: 35px; */
}

.field input {
    width: 50%;
}

/* .field > div {
    width: 67%;
} */

.fieldB {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 144.5%; */
}


.message-container {
    height: 150px;
    z-index: 100;
    /* position: absolute; */
    /* position: sticky; */
    /* top: 150%; */
    background-color: rgba(110, 239, 144, 0.914);
    /* Прозрачный белый фон */
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    /* Тень */
    position: sticky;
    top: 40%;
    display: flex;
    justify-content: center;
    text-align: center;

}


.messageGreen {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.message-container p {
    margin: 0;
    text-align: center;
}

.paketInfo {
    font-size: 14px;
    padding-bottom: 20px;
}













@media screen and (max-width: 576px) {

    #btn1 {
        visibility: hidden;
    }

    #btn2 {
        visibility: visible;
        display: flex;
        justify-content: center;
    }


    .label1,
    .label2 {
        margin: 0;
        width: 90px;
    }

    .dpContainer {
        margin: 15px auto 10px 23px;
    }

    .form {
        margin: 0 7px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: auto;
        gap: 0;
    }


    .personContainer {
        text-align: center;
        height: 710px;
        background-color: #d6eff5;
        /* border-radius: 10px; */
        padding: 10px;
        margin: 0;
    }

    #touren {
        padding: 0 !important;
    }

    .timePicker1 input {
        width: 40px;
    }



    .field1 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 22px;
    }

    .motoDetails {
        height: -moz-fit-content;
        height: fit-content;
        position: sticky;
        top: 90px;
        width: 266px;
        background-color: #C7EBFC;
        padding: 4px 15px 15px;
        color: #211818;
        border-radius: 10px;
        transition: top 0.5s ease;
        /* margin-bottom: 20px; */
        margin: 20px auto;
    }

    .formLeft {
        padding: 0 10px;
    }

    .formRight {
        padding: 10px;
    }

    .restDataBtn {
        display: flex;
        justify-content: center;
    }

    .btn {
        width: 84%;
    }

    .firstForm {
        margin-top: 50px;
    }


    .btnContainer {
        margin: 10px 0 20px;
    }


    .persDat {
        /* width: auto; */
        width: 100%;
        padding-top: 10px;
    }

    .contactContainer {
        margin: 70px 0 90px;
        width: auto;
    }

    .persDat p {
        padding: 0;
    }

    .form label,
    .formIn label {
        display: block;
        margin-bottom: 0.3rem;
        font-weight: bold;
        font-size: 13px;
        text-align: left;
    }

    .contactInfo2 {
        /* padding-left: 35px; */
        padding: 0 10px;
    }

    .field input {
        width: 60%;
    }

    .field .react-datepicker__input-container input {
        margin-right: 23px;
    }


}


@media screen and (min-width: 1024px) and (max-width: 1800px) {


    .personContainer {
        /* text-align: center; */
        height: 600px;
        background-color: #d6eff5;
        /* border-radius: 10px; */
        padding: 125px;
        margin: 0;
        height: 550px;
        gap: 100px;
    }

    .motoPageRight h1 {
        font-size: 30px;
    }

    .motoPageRight h2 {
        font-size: 25px;
    }

    .motoPageRight p {
        margin: 0;
        font-size: 17px;
    }

}


@media screen and (min-width: 390px) and (max-width: 1024px) {

    .motoPageRight h1 {
        font-size: 30px;
    }

    .motoPageRight h2 {
        font-size: 25px;
    }

    .motoPageRight p {
        margin: 0;
        font-size: 17px;
    }

    .form {
        gap: 5px;
        margin: 10px;
    }

    #personDaten {

        height: 1020px;
        margin-top: 100px;

    }

    .personContainer {
        text-align: center;
        background-color: #d6eff5;
        /* border-radius: 10px; */
        padding: 43px;
        margin: 0;
        height: 820px;
        /* width: 100%; */

    }

    .slider {
        padding-top: 50px;
        height: auto;
        background-image: url(http://localhost:3000/static/media/wood.8e3b6b9….jpg);
        background-repeat: no-repeat;
        background-size: 120%;
    }

    .zusatz_info2{
        width: 235px;
    }

    .motoDetailsContainer {
        padding: 0 !important;
    }

    .details_time b{
        font-size: 14px;
    }

    .details_time p {
        font-size: 14px;
    }

    #personDaten h3 {
        font-size: 16px;
        padding-top: 0;
    }
    
    .datenText{
        display: none;
    }

    .datenText2{
        display: block;
    }

    .contactContainer {
        margin: 0;
        width: auto;
    }

    .react-tel-input {
        width: 64% !important;
    }

}