.mietpakete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}

.mietpakete-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  gap: 2rem 7rem;
  justify-items: center;
}

.mietpaket {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1rem;
  width: 100%;
  padding: 2.5rem;
}

.mietpaket_red {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 2.5rem;
  width: 100%;
  background-color: #E30007;
  color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
}



.mietpaket_blue {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 2.5rem;
  width: 100%;
  background-color: #007ABB;
  color: white;
}


.mietpaket-title {
  font-size: 1.5rem;
  margin: 0;
}

.text {
  color: #E30007;
  font-size: 40px;
}

.mietpaket-description {
  font-size: 1rem;
  margin: 0;
}

.mietpaket-features p {
  margin: 0;
}



.mietpaket1 {
  width: 60%;
}


.accordion-controls {
  display: flex;
  gap: 15px;
}

.accordion-controls button {
  padding: 10px;
}

.mietpaket-h {
  margin: 0;
  font-size: 50px;
}


.mietpaket-btn {
  background: none;
  color: #E30007;
  border: solid 3px;
  border-color: #E30007;
  transition: 0.5s ease;

}

.mietpaket-btn:hover {
  background: #E30007;
  color: rgb(244, 235, 235);

}


@media screen and (max-width: 576px) {


  .text {
    font-size: 35px;
  }


  .mietpakete-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(300px, 1fr));
    gap: 2rem 7rem;
    justify-items: center;
  }


  .mietpaket_red,
  .mietpaket,
  .mietpaket_blue {
    width: 65%;
  }

  .mietpaketText {
    margin: 30px 40px;
  }


  .accordion-controls {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

}


@media screen and (min-width: 576px) and (max-width: 992px) {
  .mietpakete-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(300px, 1fr));
    gap: 2rem 7rem;
    justify-items: center;
  }

  .mietpaket_red,
  .mietpaket,
  .mietpaket_blue {
    width: 65%;
  }

  .mietpaketText {
    margin: 30px 40px;
  }


  .accordion-controls {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

}




@media screen and (min-width: 1024px) and (max-width: 2000px) {

  .mietpakete-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    gap: 2rem 1rem;
    justify-items: center;
  }

  .mietpaket_blue {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 2.5rem;
    width: 80%;
    background-color: #007ABB;
    color: white;
  }

  .mietpaket_red {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 2.5rem;
    width: 80%;
    background-color: #E30007;
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }

  .mietpaket {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 1rem;
    width: 80%;
    padding: 2.5rem;
  }

  .accordion-controls {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
  }


}